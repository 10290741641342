@media (max-width: 767px) {
    .header-da {
        text-align: center;
        display: block !important;
    }
    .header-da img {
        padding-bottom: 15px;
    }
    .contact-da {
        justify-content: space-between !important;
    }
}

body {
    background: #fffbcc url(./img/bg-pattern.png);
    background-color: #fffbcc !important;
}

body.login {
    height: 100vh;
}

body {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center !important;
    padding-bottom: 40px;
}

#root {
    width: 100%;
}

.logo-da {
    width: 250px;
}
.bg-da {
    background: #ffffff;
}
h6 a,
.recovery-link {
    text-decoration: underline;
    color: #212529;
}
h6 a:hover,
.recovery-link:hover {
    color: #212529;
    text-decoration: none;
}
li {
    list-style-image: url(./img/list-marker.png) !important;
    margin-bottom: 15px;
}
.danger-da {
    color: #ff0000;
    font-weight: bold;
}
.phone-da {
    margin-right: 25px;
}
.phone-da p:first-child {
    font-size: 19px;
    margin: 0px;
}
.phone-da p:first-child a {
    color: #212529;
}
.phone-da p:last-child {
    font-size: 8.3px;
    margin: 0px;
}
.btn-da {
    color: #fff !important;
    letter-spacing: 1px !important;
    padding: 10px 30px !important;
    background: #ff0000;
    background: -moz-linear-gradient(top, #ff0000 0%, #c42f12 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #ff0000), color-stop(100%, #c42f12));
    background: -webkit-linear-gradient(top, #ff0000 0%, #c42f12 100%);
    background: -o-linear-gradient(top, #ff0000 0%, #c42f12 100%);
    background: -ms-linear-gradient(top, #ff0000 0%, #c42f12 100%);
    background: linear-gradient(to bottom, #ff0000 0%, #c42f12 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0000', endColorstr='#c42f12', GradientType=0);
    border: 0 !important;
}
.btn-da:hover {
    background: #ff0000;
    letter-spacing: 1px !important;
    padding: 10px 30px !important;
    background: -moz-linear-gradient(top, #ff0000 0%, #ff0000 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #ff0000), color-stop(100%, #ff0000));
    background: -webkit-linear-gradient(top, #ff0000 0%, #ff0000 100%);
    background: -o-linear-gradient(top, #ff0000 0%, #ff0000 100%);
    background: -ms-linear-gradient(top, #ff0000 0%, #ff0000 100%);
    background: linear-gradient(to bottom, #ff0000 0%, #ff0000 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0000', endColorstr='#ff0000', GradientType=0);
    text-decoration: none;
    border: 0 !important;
}
.contact-da {
    display: flex;
}

/* help-clases */
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.text-justify {
    text-align: justify;
}
.text-center {
    text-align: center;
}
.text-uppercase {
    text-transform: uppercase;
}
.ul-unstyled {
    margin: 0;
    padding: 0;
    list-style: none;
}
.row.no-gutters {
    margin-right: 0;
    margin-left: 0;
}
.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
    padding-right: 0;
    padding-left: 0;
}
.form-signin {
    width: 100%;
    max-width: 420px;
    padding: 15px;
    margin: auto;
}
.form-label-group {
    position: relative;
    margin-bottom: 1rem;
}
.form-label-group > input,
.form-label-group > select,
.form-label-group > label {
    height: 3.125rem;
    padding: 0.75rem;
}
.form-label-group > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    pointer-events: none;
    cursor: text;
    /* Match the input under the label */
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: all 0.1s ease-in-out;
}
.form-label-group input::-webkit-input-placeholder {
    color: transparent;
}
.form-label-group input:-ms-input-placeholder {
    color: transparent;
}
.form-label-group input::-ms-input-placeholder {
    color: transparent;
}
.form-label-group input::-moz-placeholder {
    color: transparent;
}
.form-label-group input::placeholder {
    color: transparent;
}
.form-label-group input:not(:placeholder-shown) {
    padding-top: 1.25rem;
    padding-bottom: 0.25rem;
}

.form-label-group select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-top: 1.25rem;
    padding-bottom: 0.25rem;
}

.form-label-group select ~ label {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-size: 12px;
    color: #777;
}

.form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-size: 12px;
    color: #777;
}
/* Fallback for Edge
  -------------------------------------------------- */
@supports (-ms-ime-align: auto) {
    .form-label-group > label {
        display: none;
    }
    .form-label-group input::-ms-input-placeholder {
        color: #777;
    }
}
/* Fallback for IE
  -------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .form-label-group > label {
        display: none;
    }
    .form-label-group input:-ms-input-placeholder {
        color: #777;
    }
}

/* Стили калькулятора */
/* .calculator_01_left,
.calculator_01_right {
    padding-left: 15px;
    padding-right: 15px;
    width: 49%;
    display: inline-block;
    vertical-align: top;
}
.calculator_02_line,
.calculator_02_line_r,
.calculator_01_line,
.calculator_01_line_r {
    position: relative;
    text-transform: uppercase;
    font-size: 20px !important;
    font-family: Intro Regular, Arial, Helvetica, sans-serif;
    color: #5b5b5b;
}
.calculator_02_line input,
.calculator_02_line_r input,
.calculator_01_line input,
.calculator_01_line_r input {
    position: absolute;
    right: 0px;
    font-size: 18px;
    width: 100px;
    height: 30px;
    border: 2px solid #ddd !important;
    border-radius: 4px;
}
.calculator_02_range,
.calculator_02_range_r,
.calculator_01_range,
.calculator_01_range_r {
    width: 100%;
    position: relative;
    margin-bottom: 40px;
    margin-top: 20px;
}

.calculator_02_range:after,
.calculator_02_range:before,
.calculator_02_range_r:after,
.calculator_02_range_r:before,
.calculator_01_range:after,
.calculator_01_range:before,
.calculator_01_range_r:after,
.calculator_01_range_r:before {
    position: absolute;
    font-family: Intro Regular;
    color: #5b5b5b;
    top: 25px;
}

.calculator_02_range:before {
    content: "50 000 руб.";
}
.calculator_02_range:after {
    content: "1 млн. руб.";
    right: 0px;
}
.calculator_02_range_r:before {
    content: "1 мес.";
}
.calculator_02_range_r:after {
    content: "12 мес.";
    right: 0px;
}

.calculator_01_range:before {
    content: "1 000 руб.";
}
.calculator_01_range:after {
    content: "30 000 руб.";
    right: 0px;
}
.calculator_01_range_r:before {
    content: "1 день";
}
.calculator_01_range_r:after {
    content: "30 дней";
    right: 0px;
} */

input[type="range"] {
    -webkit-appearance: none; /* Скрывает слайдер, чтобы можно было создать свой */
    width: 100%; /* Указание параметра ширины требуется для Firefox. */
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
}

input[type="range"]:focus {
    outline: none !important; /* Убирает голубую границу у элемента. Хотя, возможно, и стоит создавать некоторое оформления для состояния фокуса в целях обеспечения доступности. */
}

.custom-range:focus::-webkit-slider-thumb {
    box-shadow: none !important;
}
.custom-range:focus::-moz-range-thumb {
    box-shadow: none !important;
}
.custom-range:focus::-ms-thumb {
    box-shadow: none !important;
}

input[type="range"]::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent; /* Скрывает слайдер, чтобы можно было добавить собственные стили. */
    border-color: transparent;
    color: transparent;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background: #ffffff !important;
    background: -moz-radial-gradient(center, ellipse cover, #ffffff 0%, #b0b0b0 100%) !important;
    background: -webkit-gradient(
        radial,
        center center,
        0px,
        center center,
        100%,
        color-stop(0%, #ffffff),
        color-stop(100%, #b0b0b0)
    ) !important;
    background: -webkit-radial-gradient(center, ellipse cover, #ffffff 0%, #b0b0b0 100%) !important;
    background: -o-radial-gradient(center, ellipse cover, #ffffff 0%, #b0b0b0 100%) !important;
    background: -ms-radial-gradient(center, ellipse cover, #ffffff 0%, #b0b0b0 100%) !important;
    background: radial-gradient(ellipse at center, #ffffff 0%, #b0b0b0 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#B0B0B0', GradientType=1 ) !important;
    cursor: pointer;
    margin-top: -6px;
}
input[type="range"]::-moz-range-thumb,
input[type="range"]::-ms-thumb {
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background: #ffffff !important;
    background: -moz-radial-gradient(center, ellipse cover, #ffffff 0%, #b0b0b0 100%) !important;
    background: -webkit-gradient(
        radial,
        center center,
        0px,
        center center,
        100%,
        color-stop(0%, #ffffff),
        color-stop(100%, #b0b0b0)
    ) !important;
    background: -webkit-radial-gradient(center, ellipse cover, #ffffff 0%, #b0b0b0 100%) !important;
    background: -o-radial-gradient(center, ellipse cover, #ffffff 0%, #b0b0b0 100%) !important;
    background: -ms-radial-gradient(center, ellipse cover, #ffffff 0%, #b0b0b0 100%) !important;
    background: radial-gradient(ellipse at center, #ffffff 0%, #b0b0b0 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#B0B0B0', GradientType=1 ) !important;
    cursor: pointer;
}
.calculator_01_left input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 7px;
    border-radius: 4px;
    cursor: pointer;
    background: #ff0000 !important;
    background: -moz-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: -webkit-gradient(left top, right top, color-stop(0%, #ff0000), color-stop(100%, #c23223)) !important;
    background: -webkit-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: -o-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: -ms-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: linear-gradient(to right, #ff0000 0%, #c23223 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FF0000', endColorstr='#C23223', GradientType=1 ) !important;
}

.calculator_01_left input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #ff0000 !important;
    background: -moz-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: -webkit-gradient(left top, right top, color-stop(0%, #ff0000), color-stop(100%, #c23223)) !important;
    background: -webkit-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: -o-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: -ms-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: linear-gradient(to right, #ff0000 0%, #c23223 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FF0000', endColorstr='#C23223', GradientType=1 ) !important;
}

.calculator_01_left input[type="range"]::-moz-range-track {
    width: 100%;
    height: 7px;
    border-radius: 4px;
    cursor: pointer;
    background: #ff0000 !important;
    background: -moz-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: -webkit-gradient(left top, right top, color-stop(0%, #ff0000), color-stop(100%, #c23223)) !important;
    background: -webkit-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: -o-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: -ms-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: linear-gradient(to right, #ff0000 0%, #c23223 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FF0000', endColorstr='#C23223', GradientType=1 ) !important;
}

.calculator_01_left input[type="range"]::-ms-track {
    width: 100%;
    height: 7px;
    border-radius: 4px;
    cursor: pointer;
    background: #ff0000 !important;
    background: -moz-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: -webkit-gradient(left top, right top, color-stop(0%, #ff0000), color-stop(100%, #c23223)) !important;
    background: -webkit-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: -o-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: -ms-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: linear-gradient(to right, #ff0000 0%, #c23223 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FF0000', endColorstr='#C23223', GradientType=1 ) !important;
}
.calculator_01_left input[type="range"]::-ms-fill-lower {
    background: #ff0000 !important;
    background: -moz-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: -webkit-gradient(left top, right top, color-stop(0%, #ff0000), color-stop(100%, #c23223)) !important;
    background: -webkit-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: -o-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: -ms-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: linear-gradient(to right, #ff0000 0%, #c23223 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FF0000', endColorstr='#C23223', GradientType=1 ) !important;
}
.calculator_01_left input[type="range"]:focus::-ms-fill-lower {
    background: #ff0000 !important;
    background: -moz-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: -webkit-gradient(left top, right top, color-stop(0%, #ff0000), color-stop(100%, #c23223)) !important;
    background: -webkit-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: -o-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: -ms-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: linear-gradient(to right, #ff0000 0%, #c23223 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FF0000', endColorstr='#C23223', GradientType=1 ) !important;
}
.calculator_01_left input[type="range"]::-ms-fill-upper {
    background: #ff0000 !important;
    background: -moz-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: -webkit-gradient(left top, right top, color-stop(0%, #ff0000), color-stop(100%, #c23223)) !important;
    background: -webkit-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: -o-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: -ms-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: linear-gradient(to right, #ff0000 0%, #c23223 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FF0000', endColorstr='#C23223', GradientType=1 ) !important;
}
.calculator_01_left input[type="range"]:focus::-ms-fill-upper {
    background: #ff0000 !important;
    background: -moz-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: -webkit-gradient(left top, right top, color-stop(0%, #ff0000), color-stop(100%, #c23223)) !important;
    background: -webkit-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: -o-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: -ms-linear-gradient(left, #ff0000 0%, #c23223 100%) !important;
    background: linear-gradient(to right, #ff0000 0%, #c23223 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FF0000', endColorstr='#C23223', GradientType=1 ) !important;
}

.mobile__pdf__container #viewerContainer {
    top: 0 !important;
    bottom: 0 !important;
}

.credit-full {
    text-transform: uppercase;
}
